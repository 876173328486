<template>
  <div class="chip-container">
    <div class="chip" v-for="(chip, i) of chips" :key="chip.label">
      {{ chip }}
      <i @click="deleteChip(i)"
        ><img src="../../../../assets/img/ico_close.svg" alt=""
      /></i>
    </div>
    <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
    <div class="inputcontainer">
      <input
        @input="VALIDATE($event)"
        type="text"
        :maxlength="parseInt(maxLen) || ''"
        step="0"
        v-model="currentInput"
        @keypress.enter="saveChip"
        @keydown.delete="backspaceDelete"
        @blur="saveChip"
      />
      <div class="plus">+</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chip",
  props: {
    set: {
      type: Boolean,
      default: true
    },
    initValue: {
      type: Array,
      required: false
    },
    validation: {
      type: String
    },
    maxLen: {
      type: String
    }
  },
  data() {
    return {
      chips: [],
      currentInput: ""
    };
  },
  mounted() {
    if (this.initValue) this.chips = [...this.initValue];
  },
  watch: {
    chips: {
      deep: true,
      immediate: true,
      handler(n) {
        // this.$env.CONSOLE.log("CHIPS:", n);
        this.$emit("input", n);
      }
    }
  },
  methods: {
    VALIDATE(e) {
      if (typeof this.validation === "undefined") return;

      if (this.validation === "PHONE") {
        let elem = e.target;
        if (elem.value.length > elem.maxLength)
          this.currentInput = elem.value.slice(0, elem.maxLength);
        this.currentInput = elem.value.replace(/\D/g, "");
      }
    },

    saveChip() {
      const { chips, set } = this;
      let { currentInput } = this;
      currentInput = currentInput.trim();
      if (!currentInput.trim()) {
        this.currentInput = currentInput.trim();
        return;
      }
      ((set && chips.indexOf(currentInput) === -1) || !set) &&
        chips.push(currentInput);
      this.currentInput = "";
    },

    deleteChip(index) {
      this.chips.splice(index, 1);
    },

    backspaceDelete({ which }) {
      which == 8 &&
        this.currentInput === "" &&
        this.chips.splice(this.chips.length - 1);
    }
  }
};
</script>

<style scoped>
.chip-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  border-radius: 0.5vw;
  background: #f5f9fd;
  line-height: 1.3;
  color: #4da0df;
  padding: 0.2vw;
  margin: 0.5vh 0 0 0;
  border: 1px #cce2f9 solid;
  /* box-shadow: inset 2px 2px 0 0 #eff2f7; */
}

.chip-container .chip {
  margin: 0.2vw;
  background: #d9e8f5;
  padding: 0 0 0 0.5vw;
  border-radius: 0.2vw;
  display: flex;
  align-items: center;
}

i {
  cursor: pointer;
  opacity: 0.5;
  border-radius: 0.2vw;
  margin: 0.2vw;
  display: flex;
  align-items: center;
}

i img {
  height: 1.2vw;
  width: auto;
}
i:hover,
i img:hover {
  background: #ccddec;
  opacity: 1;
}

.inputcontainer {
  flex: 1;
  display: flex;
  min-width: 6vw;
}

.chip-container input {
  border-radius: 0.2vw 0 0 0.2vw;
  line-height: 3.5vh;
  color: #4da0df;
  padding: 0 0.5vw;
  margin: 0.2vw 0 0.2vw 0.2vw;
  box-shadow: none;
  font-size: 1.4rem;
  flex: 1 1 auto;
  width: 100%;
  background: #dbe8f7;
  border: 1px solid #dbe8f7;
}

.chip-container input ~ .plus {
  background: #dbe8f7;
  border: 1px solid #dbe8f7;
}

.chip-container input:hover {
  background: #fdfbfa;
  border: 1px solid #e0ecf7;
}
.chip-container input:focus {
  background: #fff;
  border: 1px solid #93caf3;
}

.chip-container .plus {
  border-radius: 0 0.2vw 0.2vw 0;
  line-height: 3.5vh;
  color: #f5f9fd;
  padding: 0 0.5vw;
  margin: 0.2vw 0.2vw 0.2vw 0;
  border: none;
  box-shadow: none;
  font-size: 1.4rem;
  flex: 1 1 auto;
  cursor: pointer;
  width: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chip-container input:hover ~ .plus {
  background: #fdfbfa;
  color: #93caf3;
  border: 1px solid #e0ecf7;
  outline: none;
}

.chip-container input:focus ~ .plus {
  background: #4da0df;
  border: 1px solid #4da0df;
}

/*  on tablets portrait ********************************************************************************************/
@media (orientation: portrait) and (min-width: 501px) {
  .chip-container {
    border-radius: 1.5vw;
  }
  .chip-container .chip {
    border-radius: 1.2vw;
    padding: 0.7vw;
    margin: 0.5vw;
  }
  i img {
    height: 4vw;
  }
  .chip-container input {
    border-radius: 1.2vw 0 0 1.2vw;
    line-height: 5.8vh;
  }
  .chip-container .plus {
    border-radius: 0 1.2vw 1.2vw 0;
  }
}

/*  on phones ********************************************************************************************/
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {

.inputcontainer {
    min-width: 30vw;
}

  .chip-container {
    border-radius: 1.5vw;
  }
  .chip-container .chip {
    border-radius: 1.2vw;
    padding: 1.2vw;
    margin: 1vw;
  }
  i img {
    height: 7vw;
  }
  .chip-container input {
    border-radius: 1.2vw 0 0 1.2vw;
    line-height: 5.8vh;
  }
  .chip-container .plus {
    border-radius: 0 1.2vw 1.2vw 0;
  }
}
</style>
