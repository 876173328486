<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <h2>Hizmet Sağlayıcılar için Başvuru Formu</h2>
        <tabs-basvuru current="3" hide="6"></tabs-basvuru>

        <div class="formpart">
          <div class="formfull">
            <div class="heading mt">
              <h3>Marka tanımlama</h3>
            </div>

            <div class="formhead">
              <div>
                <p class="desc">
                  <b>{{ application.title || "" }}</b> dışında ticari elektronik ileti gönderimi yaptığınız marka veya markalarınız varsa,
                  bu markalarınız için tescil belgesinin yüklenmesi
                  <u>
                    <b>zorunludur</b>
                  </u>.
                </p>
              </div>
            </div>

            <div v-if="application.brandSet && application.brandSet.length > 0">
              <div class="izintable" v-for="(item, index) in getBrands" :key="index">
                <div>
                  <form>
                    <div class="izinline">
                      <marka-show
                        :class="{ DeleteAnim: item.ui.deleteAnim }"
                        v-if="!item.ui.input"
                        :brand="item"
                        @sil="DELETE_CLICK(item)"
                        @duzenle="DUZENLE_CLICK(item)"
                      />

                      <marka-input-update
                        v-if="item.ui.input"
                        :original="item"
                        :master="item.master"
                        @update="UPDATE_MARKA"
                        @close="CLOSE_DUZENLE"
                        :problem="problem['1-brand']"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="izintable" v-if="!UPDATE">
              <div>
                <form>
                  <div v-if="NONE" class="btn center half second" @click="state = 'NEW'">
                    <a>+ Marka Ekle</a>
                  </div>

                  <marka-input-new
                    v-if="NEW"
                    :master="getBrands.length < 1"
                    @add="ADD_NEW_MARKA"
                    @close="state = 'NONE'"
                    :problem="problem['1-brand']"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        <sonraki-bar plabel :dsbl="!next" @next="next ? NEXT() : null" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import MarkaShow from "./genel/MarkaShow";
import MarkaInputUpdate from "./genel/MarkaInputUpdate";
import MarkaInputNew from "./genel/MarkaInputNew";
import SonrakiBar from "./genel/SonrakiBar";

export default {
  name: "BelgeGirisi",

  components: {
    MarkaShow,
    MarkaInputUpdate,
    MarkaInputNew,
    SonrakiBar,
  },

  data() {
    return {
      state: "NONE", // "NEW" "UPDATE"

      problem: {
        "1-brand": "",
      },
    };
  },

  watch: {
    state(n) {
      this.$env.CONSOLE.log("STATE:", n);
    },
  },

  mounted() {
    if (this.getBrands.length === 0) {
      this.state = "NEW";
    }
  },

  computed: {
    ...mapState("applicant", ["application"]),
    ...mapGetters("applicant", ["getBrands"]),

    NONE() {
      return this.state === "NONE";
    },

    NEW() {
      return this.state === "NEW";
    },

    UPDATE() {
      return this.state === "UPDATE";
    },

    next() {
      return (
        this.getBrands.length > 0 &&
        this.NONE &&
        !this.getBrands.find((e) => e.brandStatus === "REJECTED")
      );
    },
  },

  methods: {
    ...mapActions("applicant", [
      "belge_listesi_cek",
      "marka_ekle",
      "marka_sil",
    ]),

    ...mapMutations("applicant", ["SET_APPLICATION"]),

    BELGE_LISTESI_UPDATE() {
      this.belge_listesi_cek().then((res) => {
        this.SET_APPLICATION(res.payload);
      });
    },

    DELETE_CLICK(brand) {
      this.marka_sil(brand.id).then((res) => {
        brand.ui.deleteAnim = true;
        setTimeout(() => (brand.ui.deleteAnim = false), 500);
        this.BELGE_LISTESI_UPDATE();
      });
    },

    CLOSE_DUZENLE() {
      this.application.brandSet.forEach((e) => (e.ui.input = false));
      this.state = "NONE";
      window.scrollTo(0, 0);
    },

    DUZENLE_CLICK(brand) {
      this.application.brandSet.forEach((e) => (e.ui.input = false));
      let marka = this.application.brandSet.find((e) => e.id === brand.id);
      marka.ui.input = true;
      this.state = "UPDATE";
    },

    ADD_NEW_MARKA(entry) {
      this.$env.CONSOLE.log(
        "ADD NEW MARKA (entry):",
        JSON.parse(JSON.stringify(entry))
      );

      this.marka_ekle({
        master: entry.master,
        brandName: entry.name,
        props: entry.props,
        file: entry.ui.file,
        // brandId : null,
        fileUpdated: false,
        ekleType: "NEW",
      })
        .then((res) => {
          this.SET_APPLICATION(res.payload);
          this.state = "NONE";
          window.scrollTo(0, 0);
          // _paq.push(["trackEvent", "Devam", "Devam_Belge_Yukle", "Success"]);
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Devam", "Devam_Belge_Yukle", "Error"]);
          this.HandleValidationError(cerror);
        });
    },

    UPDATE_MARKA(entry) {
      this.$env.CONSOLE.log(
        "UPDATE MARKA (entry):",
        JSON.parse(JSON.stringify(entry))
      );

      this.marka_ekle({
        master: entry.master,
        brandName: entry.name,
        brandId: entry.id,
        props: entry.props,
        fileUpdated: entry.ui.fileUpdated,
        file: entry.ui.fileUpdated ? entry.ui.file : null,
        ekleType: "UPDATE",
      })
        .then((res) => {
          this.SET_APPLICATION(res.payload);
          this.CLOSE_DUZENLE();
          // _paq.push(["trackEvent", "Devam", "Devam_Belge_Yenile", "Success"]);
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Devam", "Devam_Belge_Yenile", "Error"]);
          this.HandleValidationError(cerror);
        });
    },

    NEXT() {
      this.$router.push("/hizmet-saglayici/basvuru/onaylama");
    },
  },
};
</script>

<style scoped>
.DeleteAnim {
  animation: deleteanim 0.5s infinite;
}

@keyframes deleteanim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.formline.xpad {
  padding: 1vw 1vw 0 1vw !important;
}

.bgc {
  background: #fcfcfc;
}

.bgc form .btn {
  margin: 0 auto;
}

.markabox + .markadata {
  margin-top: 5vh;
}

/*  on big screens ********************************************************************************************/

@media only screen and (min-height: 1000px) {
  .branddel a {
    padding: 0.2vw 0.5vw;
  }
}

/*  on tablets portrait ********************************************************************************************/
@media (orientation: portrait) and (min-width: 501px) {
}

/*  on phones ********************************************************************************************/
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
