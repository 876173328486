<template>
  <div class="tripleone">
    <div v-show="!file">
      <div class="formlabel">
        <label>Marka Tescil Belgesi</label>
        <tool-tip class="right" v-if="showToolip">Zorunlu değildir.</tool-tip>
        <label class="max" >En fazla 12 MB.</label>
      </div>

      <input
        id="myFile"
        ref="myFile"
        class="inputfile"
        type="file"
        name="#"
        value
        @click="CLICK_EKLE($event)"
        @change="FILE_EKLE_CHANGED($event, docType)"
        accept=".pdf"
      />
      <label for="myFile"
        ><img src="@/assets/img/ico_upload.svg" alt />Ekle (PDF)</label
      >
    </div>

    <div v-if="file">
      <div class="formleft formcard">
        <div class="files">
          <div class="fileline">
            <img
              :src="require(`@/assets/img/${file_type_map['application/pdf']}`)"
            />

            <div class="filetext">
              <div class="filename">
                {{ file.name | toFileName }}
              </div>
            </div>

            <div class="okim">
              <img src="@/assets/img/ico_tick_green.svg" alt />
            </div>
            <div class="delete" @click="REMOVE_FILE_CLICK">
              <img src="@/assets/img/ico_remove.svg" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "InputFileUpdate",
  props: {
    original: {
      type: Object,
      default: null
    },
    docType: {
      type: String,
      required: true
    },
    showToolip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      file: null,

      belge_turu_map: require("../../../../misc/enums").ENUM_BELGE_TURU,
      file_type_map: require("../../../../misc/enums").ENUM_BELGE_UZANTISI_SVG
    };
  },

  created() {
    this.file = this.original;
  },

  watch: {
    file: {
      immediate: true,
      handler(n) {
        this.$emit("input", n);
      }
    }
  },
  methods: {
    REMOVE_FILE_CLICK() {
      let loader = this.$loading.show();
      setTimeout(async () => {
        loader.hide();
        this.$refs.myFile.value = "";
        this.file = null;
        this.$emit("removed");
      }, Math.random() * 200 + 200);
    },

    CLICK_EKLE(e) {
      e.srcElement.value = "";
    },

    async FILE_EKLE_CHANGED(e, belge_turu) {
      let loader = this.$loading.show();
      setTimeout(async () => {
        loader.hide();

        if (e.srcElement.value === "") return;

        let target = e.target;

        if (target.files[0] === undefined) {
          await this.Alert("Dosya seçimi başarısız oldu.");
          this.$env.CONSOLE.warn("FILE UNDEFINED");
          return;
        }

        try {
          const fileSize = (target.files[0].size / 1024 / 1024).toFixed(4);

          if (fileSize > 12) {
            await this.Alert("Dosya boyutu 12 MB'dan küçük olmalıdır.");
            return;
          }

          if (fileSize === 0) {
            await this.Alert("Dosya boyutu 0 MB olamaz.");
            return;
          }
        } catch (error) {}

        this.file = target.files[0];

        this.$emit("updated");
      }, Math.random() * 200 + 200);
    }
  }
};
</script>

<style scoped>

.max {  text-align: right; margin: 0 0 0 auto; font-size: .9rem !important; }


.formtriple .inputfile + label {
  height: unset !important;
  line-height: 2.5rem;
  padding: 0.5vw;
  margin-top: 0.5vh;
  cursor: pointer;
}

.markadata label {
  font-size: 1rem;
  cursor: auto;
}
.formtriple .inputfile + label img {
  height: 2vh;
  width: 2vh;
  border-radius: 0;
}
.formlabel {
  display: flex;
  /* justify-content: space-between; */
}

.tripleone {
  padding-left: 0.5vw;
}

/*  on tablets portrait ********************************************************************************************/
@media (orientation: portrait) and (min-width: 501px) {
  .formtriple .inputfile + label {
    line-height: 7vh;
  }
}

/*  on phones ********************************************************************************************/
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .formtriple .inputfile + label {
    line-height: 7vh;
  }
}
</style>
